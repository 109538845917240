/* Lexend - Thin Normal */
@font-face {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 100;
    src: local(''), 
    url('../assets/fonts/Lexend-Thin.ttf') format('truetype');
  }
  
  /* Lexend - Extra Light */
  @font-face {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 200;
    src: local(''), 
    url('../assets/fonts/Lexend-ExtraLight.ttf') format('truetype');
  }
  
  /* Lexend - Light */
  @font-face {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    src: local(''), 
    url('../assets/fonts/Lexend-Light.ttf') format('truetype');
  }

  /* Lexend - Book */
  @font-face {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    src: local(''), 
    url('../assets/fonts/Lexend-Regular.ttf') format('truetype');
  }
  
  /* Lexend - Regular */
  @font-face {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    src: local(''), 
    url('../assets/fonts/Lexend-Medium.ttf') format('truetype');
  }
  
  /* Lexend - Bold */
  @font-face {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    src: local(''), 
    url('../assets/fonts/Lexend-SemiBold.ttf') format('truetype');
  }
  
  /* Lexend - Extra Bold */
  @font-face {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 700;
    src: local(''), 
    url('../assets/fonts/Lexend-Bold.ttf') format('truetype');
  }
  
  /* Lexend - Heavy */
  @font-face {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 800;
    src: local(''), 
    url('../assets/fonts/Lexend-ExtraBold.ttf') format('truetype');
  }
  
  /* Lexend - Black */
  @font-face {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 900;
    src: local(''), 
    url('../assets/fonts/Lexend-Black.ttf') format('truetype');
  }
  